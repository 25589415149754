import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { CartService } from '../services/cart/cart.service';
import { ThemeService } from '../services/theme/theme.service';

@Injectable({
  providedIn: 'root',
})
export class MainDispatcher {
  private routeData: any;
  private settingsSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private cartSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private personalInfoSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private deliverySubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private gatewayCredentials: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private shipmentSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private shipmentsSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private paymentSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private orderSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private couponSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private orderBumpSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private upsellSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private paymentReturnSubj: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isPreviewRoute: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cartService: CartService,
    private themeService: ThemeService
  ) {
    this.loadRouteData();
  }

  listenerSettings(): Observable<any> {
    return this.settingsSubj;
  }

  listenerCart(): Observable<any> {
    return this.cartSubj;
  }

  listenerPersonalInfo(): Observable<any> {
    return this.personalInfoSubj;
  }

  listenerDelivery(): Observable<any> {
    return this.deliverySubj;
  }

  listenerGatewayCredentials(): Observable<any> {
    return this.gatewayCredentials;
  }

  listenerShipment(): Observable<any> {
    return this.shipmentSubj;
  }

  listenerShipments(): Observable<any> {
    return this.shipmentsSubj;
  }

  listenerPayment(): Observable<any> {
    return this.paymentSubj;
  }

  listenerOrder(): Observable<any> {
    return this.orderSubj;
  }

  listenerCoupon(): Observable<any> {
    return this.couponSubj;
  }

  listenerOrderBump(): Observable<any> {
    return this.orderBumpSubj;
  }

  listenerUpsell(): Observable<any> {
    return this.upsellSubj;
  }

  listenerPaymentReturn(): Observable<any> {
    return this.paymentReturnSubj;
  }

  getIsPreviewRoute() {
    return this.isPreviewRoute;
  }

  resetAll() {
    this.settingsSubj = new BehaviorSubject<any>({});
    this.cartSubj = new BehaviorSubject<any>(null);
    this.personalInfoSubj = new BehaviorSubject<any>({});
    this.deliverySubj = new BehaviorSubject<any>({});
    this.shipmentSubj = new BehaviorSubject<any>({});
    this.shipmentsSubj = new BehaviorSubject<any>({});
    this.paymentSubj = new BehaviorSubject<any>(null);
    this.orderSubj = new BehaviorSubject<any>({});
    this.couponSubj = new BehaviorSubject<any>({});
    this.orderBumpSubj = new BehaviorSubject<any>({});
    this.upsellSubj = new BehaviorSubject<any>({});
    this.paymentReturnSubj = new BehaviorSubject<any>(null);
  }

  savePersonalInfo(data: any) {
    this.personalInfoSubj.next(data);
  }

  getGatewayCredentials(storeId: string) {
    this.requestGatewayCredentials(storeId).subscribe({
      next: this.treatGetGatewayCredentialsSuccesfull,
      error: this.treatGetGatewayCredentialsError,
    });
  }

  selectDelivery(deliveryAddress: any) {
    this.deliverySubj.next(deliveryAddress);
  }

  getShipments() {
    this.requestGetShipment().subscribe({
      next: this.treatGetShipmentSuccesfull,
      error: this.treatGetShipmentError,
    });
  }

  getCart() {
    const { storeId, cartId } = this.routeData;
    this.requestGetCart(storeId, cartId).subscribe({
      next: this.treatGetCartSuccesfull,
      error: this.treatGetCartError,
    });
  }

  getStoreSettings(storeId: string) {
    this.requestGetStoreSettings(storeId).subscribe({
      next: this.treatGetStoreSettingsSuccesfull,
    });
  }

  selectShipment(shipment: any) {
    this.shipmentSubj.next(shipment);
  }

  selectPayment(payload: any) {
    this.paymentSubj.next(payload);
  }

  changeItemQuantity(item: any, newQuantity: number) {
    this.cartSubj.next({
      ...this.cartSubj.value,
      cartItems: [
        ...this.cartSubj.value.cartItems.map((cartItem: any) => {
          return cartItem.id === item.id
            ? {
                ...cartItem,
                quantity: newQuantity,
                price: newQuantity * cartItem.unitPrice,
              }
            : cartItem;
        }),
      ],
    });
  }

  setPaymentReturn(res: any) {
    this.paymentReturnSubj.next(res);
  }

  getOrder({ storeId, orderId }: any): Observable<any> {
    return this.cartService.getOrder(storeId, orderId);
  }

  finishOrder(data: any): Observable<any> {
    switch (data.paymentType) {
      case 'card':
        return this.cartService.postCardPayment(data.payload);
      case 'pix':
        return this.cartService.postPixPayment(data.payload);
      case 'bankSlip':
        return this.cartService.postBankSlipPayment(data.payload);
      default:
        return of({});
    }
  }

  startNewBuy(skuId: any) {
    this.settingsSubj = new BehaviorSubject<any>({});
    this.cartSubj = new BehaviorSubject<any>({});
    this.personalInfoSubj = new BehaviorSubject<any>({});
    this.deliverySubj = new BehaviorSubject<any>({});
    this.shipmentSubj = new BehaviorSubject<any>({});
    this.paymentSubj = new BehaviorSubject<any>({});
    this.orderSubj = new BehaviorSubject<any>({});
    this.couponSubj = new BehaviorSubject<any>({});
    this.orderBumpSubj = new BehaviorSubject<any>({});
    this.upsellSubj = new BehaviorSubject<any>({});
    this.routeData = null;
    this.router.navigate([`/?pid=${skuId}`]);
    this.loadRouteData();
  }

  private loadRouteData() {
    this.isPreviewRoute = this.router.url.includes('/pre-visualizacao');

    this.routeData = {
      ...this.route.snapshot.queryParams,
      ...this.route.snapshot.params,
    };
    if (Object.keys(this.routeData).length <= 0 && !this.isPreviewRoute) {
      this.invalidData();
    } else if (this.routeData.storeId && this.routeData.cartId && !this.isPreviewRoute) {
      this.loadSettings();
    }
  }

  private loadSettings() {
    const { storeId } = this.routeData;
    this.getCart();
    this.getShipments();
    this.getGatewayCredentials(storeId);
    this.getStoreSettings(storeId);
  }

  private treatGetCartSuccesfull = (res: any): void => {
    this.cartSubj.next({
      originalCart: res?.cart,
      cartItems: res?.cart?.items,
    });
  };

  private treatGetCartError = (error?: any) => {
    this.cartSubj.next(this.parseError(error));
  };

  treatGetStoreSettingsSuccesfull = (res: any): void => {
    if (res?.checkoutTheme) this.themeService.start(res?.checkoutTheme);
  };

  private treatGetGatewayCredentialsSuccesfull = (res: any): void => {
    this.gatewayCredentials.next({ ...res });
  };

  private treatGetGatewayCredentialsError = (error?: any) => {
    this.gatewayCredentials.next(this.parseError(error));
  };

  private treatGetShipmentSuccesfull = (res: any): void => {
    this.shipmentsSubj.next({ shipments: res?.data });
  };

  private treatGetShipmentError = (error?: any) => {
    const _error = error?.status === 404 ? { shipments: [] } : error;
    this.shipmentSubj.next(_error);
  };

  private invalidData = (error?: any): void => {
    this.router.navigate(['/erro']);
  };

  private parseError = (httpError: any, message: string = 'Ocorreu um erro, tente mais tarde'): any => {
    return {
      errorMessage:
        httpError?.error?.message && typeof httpError?.error?.message === 'string'
          ? httpError?.error?.message
          : message,
    };
  };

  private requestGetCart(storeId: string, cartId: string): Observable<any> {
    return this.cartService.getCart(storeId, cartId);
  }

  requestGetStoreSettings(storeId: string): Observable<any> {
    return this.themeService.getStoreSettings(storeId);
  }

  private requestGatewayCredentials(storeId: string): Observable<any> {
    return this.cartService.getGatewayCredentials(storeId);
  }

  private requestGetShipment(): Observable<any> {
    return this.cartService.getShipments({
      storeId: this.routeData.storeId,
      query: { active: true },
    });
  }
}
