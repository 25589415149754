<app-card [orientation]="'FOOTER'" [disabled]="false">
  <div class="footer">
    <div class="logo-wrapper">
      <div class="logo"></div>
      <p>
        {{ footerText }}
      </p>
      <hr />
      <div class="links">
        <a *ngIf="firstLink" [href]="firstLink.url" target="_blank">
          {{ firstLink.description }}
        </a>
        <a *ngIf="secondLink" [href]="secondLink.url" target="_blank">
          {{ secondLink.description }}
        </a>
      </div>
    </div>
  </div>
</app-card>
