<div
  class="toast"
  (mouseenter)="hovered = true"
  (mouseleave)="hovered = false"
  [ngClass]="{
    error: type === 'ERROR',
    success: type === 'SUCCESS',
    info: type === 'INFO'
  }"
>
  <div *ngIf="closable && hovered" class="closable-container">
    <button mat-mini-fab (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-content></ng-content>
</div>
