import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LayoutModule } from '@angular/cdk/layout';

import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { CardComponent } from './components/card/card.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { FullscreenLoaderComponent } from './components/fullscreen-loader/fullscreen-loader.component';
import { InPageToastComponent } from './components/in-page-toast/in-page-toast.component';
import { FullPageLoadingComponent } from './components/full-page-loading/full-page-loading.component';
import { NgxMaskPipe } from 'ngx-mask';
import { UpsellComponent } from '../pages/upsell/upsell.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskPipe,
    MatCardModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatSelectModule,
    MatGridListModule,
    MatDividerModule,
    MatAutocompleteModule,
    LayoutModule,
  ],
  declarations: [
    ToolbarComponent,
    FooterComponent,
    CardComponent,
    CreditCardComponent,
    FullscreenLoaderComponent,
    InPageToastComponent,
    FullPageLoadingComponent,
    UpsellComponent,
  ],
  exports: [
    ToolbarComponent,
    FooterComponent,
    CardComponent,
    CreditCardComponent,
    FullscreenLoaderComponent,
    InPageToastComponent,
    FullPageLoadingComponent,
    UpsellComponent,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatGridListModule,
    MatButtonModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    LayoutModule,
  ],
  providers: [],
})
export class SharedModule {}
