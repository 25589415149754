import { Component, Input } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';
import { CepService } from 'src/app/core/services/cep/cep.service';
import { _lowercaseFilter } from 'src/app/shared/utils/filters.utils';
// import { statesGrouped } from 'src/app/shared/utils/states.utils';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss'],
})
export class DeliveryComponent {
  @Input() active: boolean = false;

  isEdit: boolean = false;
  isFormOpen: boolean = true;
  isLoading: boolean = false;
  isLoadingCep: boolean = false;
  errorMessage: any;

  deliveryList: any[] = [];
  deliveryForm: FormGroup;
  deliverySelected: string;
  cartIntegrated: string;

  constructor(
    private fb: FormBuilder,
    private mainDispatcher: MainDispatcher,
    private cepService: CepService
  ) {}

  ngOnInit() {
    this.startForm();
  }

  startForm() {
    this.deliveryForm = this.fb.group({
      zipCode: ['', Validators.required],
      address: ['', Validators.required],
      number: ['', Validators.required],
      district: ['', Validators.required],
      complement: [''],
      city: ['', Validators.required],
      state: ['', [Validators.required, Validators.pattern(/^[A-Za-z]{2}$/)]],
    });
    this.listenCep();
    this.listenFormChanges();
  }

  listenFormChanges() {
    this.deliveryForm.valueChanges.subscribe((value) => {
      if (!this.deliveryForm.invalid) {
        this.mainDispatcher.selectDelivery(value);
      } else {
        this.deliveryForm.markAllAsTouched();
        this.mainDispatcher.selectDelivery(null);
      }
    });
  }

  cancel() {
    this.isEdit = false;
    this.isFormOpen = false;
    this.isLoading = false;
  }

  openNew() {
    this.isEdit = false;
    this.isFormOpen = true;
    this.deliveryForm.reset();
  }

  openEdit(delivery: any) {
    this.isEdit = true;
    this.isFormOpen = true;
    this.deliveryForm.patchValue(
      {
        zipCode: delivery.cep,
        address: delivery.endereco,
        number: delivery.numero,
        district: delivery.bairro,
        complement: delivery.complemento,
        city: delivery.cidade,
        state: delivery.estado,
      },
      { emitEvent: true }
    );
  }

  get cep(): AbstractControl | null {
    return this.deliveryForm.get('zipCode');
  }
  get address(): AbstractControl | null {
    return this.deliveryForm.get('address');
  }
  get number(): AbstractControl | null {
    return this.deliveryForm.get('number');
  }
  get district(): AbstractControl | null {
    return this.deliveryForm.get('district');
  }
  get complement(): AbstractControl | null {
    return this.deliveryForm.get('complement');
  }
  get city(): AbstractControl | null {
    return this.deliveryForm.get('city');
  }
  get state(): AbstractControl | null {
    return this.deliveryForm.get('state');
  }

  private listenCep() {
    this.cep?.valueChanges.subscribe((val) => {
      if (val?.length === 8) this.loadCep(val);
      if (val?.length === 0) this.deliveryForm.reset();
    });
  }

  private loadCep(cep: string) {
    this.isLoadingCep = true;
    this.cepService.getCep(cep).subscribe({
      next: this.treatLoadCepSuccesfull,
      error: this.treatLoadCepError,
    });
  }

  private treatLoadCepSuccesfull = (res: any): void => {
    if (res) {
      this.deliveryForm.patchValue(
        {
          address: res.logradouro,
          number: res.numero,
          district: res.bairro,
          complement: res.complemento,
          city: res.localidade,
          state: res.uf,
        },
        { emitEvent: false }
      );
    }
    this.isLoadingCep = false;
  };

  private treatLoadCepError = (error: any): void => {
    this.isLoadingCep = false;
    this.errorMessage = 'Erro ao carregar o CEP';
  };
}
