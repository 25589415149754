import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../base.service';
import { CartEndpointMapper } from '../../mappers/cart-endpoint.mapper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CartService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getCart(sellerId: string, cartId: string): Observable<any> {
    return this.http.get(CartEndpointMapper.getCart + `?${this.parseToQueryString({ sellerId, cartId })}`);
  }

  postBankSlipPayment(payload: any): Observable<any> {
    return this.http.post(CartEndpointMapper.postBankSlipPayment, payload);
  }

  postPixPayment(payload: any): Observable<any> {
    return this.http.post(CartEndpointMapper.postPixPayment, payload);
  }

  postCardPayment(payload: any): Observable<any> {
    return this.http.post(CartEndpointMapper.postCardPayment, payload);
  }

  getShipments(payload: any): Observable<any> {
    return this.http.get(
      environment.APIS.FAVOSS + `/seller/${payload.storeId}/shipping?${this.parseToQueryString(payload.query)}`
    );
  }

  getOrder(storeId: string, orderId: string): Observable<any> {
    return this.http.get(CartEndpointMapper.getOrder + `/${storeId}/order/${orderId}`);
  }

  getGatewayCredentials(storeId: string): Observable<any> {
    return this.http.get(CartEndpointMapper.getGatewayCredentials + `/${storeId}/credentials`);
  }

  verifyCoupon(storeId: string, params: any): Observable<any> {
    return this.http.get(CartEndpointMapper.getCoupon + `/${storeId}/coupons?${this.parseToQueryString(params)}`);
  }
}
