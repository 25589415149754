<!-- <app-card
  [title]="couponApplied ? coupon?.value?.toString() : 'Insira seu cupom!'"
  [subtitle]="couponApplied ? 'Cupom aplicado!' : 'Descontos exclusivos'"
  [stepCounter]="5"
  [orientation]="'COUPON'"
  [disabled]="!activeCoupon"
  (buttonClicked)="handleCardCoupon()"
  iconClosed="keyboard_arrow_down"
  iconOpened="keyboard_arrow_up"
  iconTitle="assets/images/coupon.svg"
>

  <div fxLayout="column">
    <form [formGroup]="couponForm">
      <div class="coupon-wrapper" fxLayout.lg="row" fxLayout.sm="column">
        <mat-form-field appearance="outline">
          <mat-label>Código do cupom</mat-label>
          <input
            matInput
            required="false"
            placeholder="Insira seu código"
            formControlName="coupon"
          />
          <mat-icon
            matSuffix
            *ngIf="couponForm?.touched && couponApplied"
            fontIcon="check_circle"
          />
          <mat-error *ngIf="couponForm?.touched && couponError">
            O Cupom é inválido
          </mat-error>
        </mat-form-field>

        <button
          mat-flat-button
          class="primary-button"
          [disabled]="isLoadingCoupon || couponForm.invalid || couponApplied"
          (click)="applyCoupon()"
        >
          <span *ngIf="!isLoadingCoupon">Aplicar cupom</span>
          <mat-progress-spinner
            *ngIf="isLoadingCoupon"
            diameter="25"
            strokeWidt="2"
            mode="indeterminate"
          >
          </mat-progress-spinner>
        </button>
      </div>
    </form>
  </div>
</app-card> -->

<app-card>
  <div class="values-wrapper">
    <div class="values-area">
      <span>Sub-Total</span>
      <span>{{ partialValue | currency : 'BRL' }}</span>
    </div>

    <div class="values-area">
      <span>Frete</span>
      <span *ngIf="!freeShipment && hasAddress">
        {{ shippingValue | currency : 'BRL' }}
      </span>
      <app-in-page-toast *ngIf="freeShipment && hasAddress" type="SUCCESS">
        <span>Frete Grátis</span>
      </app-in-page-toast>
      <small *ngIf="!hasAddress">Aguardando escolha da transportadora</small>
    </div>

    <div class="values-area">
      <span>Desconto</span>
      <span class="inline-toast" *ngIf="discountValue">
        <app-in-page-toast *ngIf="discountPercentageLabel.length > 0" type="INFO">
          <span>Economize {{ discountPercentageLabel }}</span>
        </app-in-page-toast>
        {{ discountValue | currency : 'BRL' }}
      </span>
      <small *ngIf="!discountValue">Nenhum cupom aplicado</small>
    </div>

    <div class="total-area">
      <span>Total</span>
      <span>{{ totalValue | currency : 'BRL' }}</span>
    </div>

    <section>
      <button
        mat-flat-button
        data-test-id="finish-order-btn"
        class="primary-button"
        [disabled]="disableBuyButton"
        (click)="finishOrder()">
        <span *ngIf="!isLoading">
          <mat-icon *ngIf="disableBuyButton" aria-hidden="false" aria-label="lock icon" fontIcon="lock" />
          Finalizar Compra
        </span>

        <mat-progress-spinner
          *ngIf="isLoading"
          diameter="25"
          strokeWidth="2"
          mode="indeterminate"></mat-progress-spinner>
      </button>
    </section>
  </div>
</app-card>

<app-in-page-toast *ngIf="couponError" [type]="'ERROR'" [closable]="true" (closed)="couponError = null">
  <span>{{ couponError }}</span>
</app-in-page-toast>
