import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, catchError, map, throttleTime } from 'rxjs';

import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let httpOptions = {
      url: request.url,
    };
    if (!request.url.includes('viacep')) {
      httpOptions = { ...httpOptions, ...this.auth.getAuthHeader() };
    }
    const dupReq = request.clone(httpOptions);
    return next
      .handle(dupReq)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            throw {
              ...err,
              error: { errors: ['Acesso expirado, favor entrar novamente.'] },
            };
          }
          throw err;
        })
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          return evt;
        })
      );
  }
}
