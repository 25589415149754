<app-card>
  <div class="values-wrapper">
    <div class="values-area">
      <span>Sub-Total</span>
      <span>{{ partialValue | currency : 'BRL' }}</span>
    </div>

    <div class="values-area">
      <span>Frete</span>
      <span *ngIf="!freeShipment && hasAddress">
        {{ shippingValue | currency : 'BRL' }}
      </span>
      <app-in-page-toast *ngIf="freeShipment && hasAddress" type="SUCCESS">
        <span>Frete Grátis</span>
      </app-in-page-toast>
      <small *ngIf="!hasAddress">Aguardando escolha da transportadora</small>
    </div>

    <div class="values-area column-flex">
      <span>
        Desconto
        <span *ngIf="couponApplied">{{ discountValue | currency : 'BRL' }}</span>
      </span>

      <form [formGroup]="couponForm">
        <mat-form-field appearance="outline" fxFlex="100" *ngIf="!couponApplied">
          <input matInput required="false" placeholder="Digite o seu cupom aqui" formControlName="couponName" />

          <button
            mat-flat-button
            matSuffix
            class="primary-button small"
            [disabled]="isLoadingCoupon || !couponForm.get('couponName')?.value"
            (click)="applyCoupon()">
            <span *ngIf="!isLoadingCoupon">Aplicar</span>
            <mat-progress-spinner
              *ngIf="isLoadingCoupon"
              diameter="14"
              strokeWidth="2"
              mode="indeterminate"></mat-progress-spinner>
          </button>
        </mat-form-field>

        <div class="coupon-applied" *ngIf="couponApplied">
          <span>{{ coupon }}</span>
          <button mat-button color="warn" (click)="removeCoupon()">
            Remover
            <mat-icon aria-hidden="true" fontIcon="close"></mat-icon>
          </button>
        </div>
      </form>
      <mat-error *ngIf="!couponApplied && couponError">Cupom inválido, expirado ou já utilizado.</mat-error>
    </div>

    <div class="total-area">
      <span>Total</span>
      <span>{{ totalValue | currency : 'BRL' }}</span>
    </div>

    <section>
      <button
        mat-flat-button
        data-test-id="finish-order-btn"
        class="primary-button"
        [disabled]="disableBuyButton"
        (click)="finishOrder()">
        <span *ngIf="!isLoading">
          <mat-icon *ngIf="disableBuyButton" aria-hidden="false" aria-label="lock icon" fontIcon="lock" />
          Finalizar Compra
        </span>

        <mat-progress-spinner
          *ngIf="isLoading"
          diameter="25"
          strokeWidth="2"
          mode="indeterminate"></mat-progress-spinner>
      </button>
    </section>
  </div>
</app-card>
