import { query } from '@angular/animations';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';
import { ToastService } from 'src/app/core/services/toast/toast.service';

@Component({
  selector: 'app-finish-order',
  templateUrl: './finish-order.component.html',
  styleUrls: ['./finish-order.component.scss'],
})
export class FinishOrderComponent {
  active: boolean = false;
  activeCoupon: boolean = false;

  couponForm: FormGroup;
  couponError: string | null;
  settings: any;
  prePaymentSelected: any;

  deliveryData: any;
  originalCart: any;
  cartItems: any[] = [];
  personalInfo: any = null;
  payment: any = null;
  shipment: any = null;
  finalForm: any = {
    customer: {},
    products: [],
    updateItems: [],
  };

  couponApplied: any = null;
  percentageDiscount: number = 0;
  realDiscount: number = 0;

  isLoading: boolean = false;
  isLoadingCoupon: boolean = false;

  constructor(
    private fb: FormBuilder,
    private mainDispatcher: MainDispatcher,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.listenCart();
    this.listenShipment();
    this.listenPersonalInfo();
    this.listenDelivery();
    // this.listenCoupon();
    this.listenPayment();
    this.startForm();
  }

  changeItemQuantity(item: any, newQuantity: number, index: number) {
    this.isLoading = true;
    this.mainDispatcher.changeItemQuantity(item, newQuantity);
  }

  applyCoupon() {
    this.isLoadingCoupon = true;
    setTimeout(() => {
      this.isLoadingCoupon = false;
      this.couponApplied = {
        valorDescontoPercentual: 10,
        valorDescontoReal: 0,
        aplicarFreteGratis: false,
      };
    }, 2000);
    // this.mainDispatcher.applyCoupon(this.couponForm.value);
  }

  handleCardCoupon() {
    this.activeCoupon = !this.activeCoupon;
  }

  checkItemsToUpdate() {
    return this.originalCart.items
      .filter((item: any) => {
        const cartItem = this.cartItems.find((cartItem) => cartItem.id === item.id);
        return cartItem && cartItem.quantity !== item.quantity;
      })
      .map((item: any) => {
        const { id, cartLineId: merchandiseId } = item;
        const { quantity } = this.cartItems.find((cartItem) => cartItem.id === item.id);
        return { id: merchandiseId, quantity, merchandiseId: id };
      });
  }

  constructFinalForm() {
    const customer = {
      email: this.personalInfo?.email,
      name: this.personalInfo?.fullName,
      document: {
        type: 'cpf',
        number: this.personalInfo?.cpf,
      },
      phoneNumber: this.personalInfo?.phone,
    };
    const customerAddress = this.deliveryData;

    const products = this.originalCart.items;
    const updateItems = this.checkItemsToUpdate();
    let otherValues: any = {
      cartId: this.route.snapshot.queryParams['cartId'],
      sellerId: this.route.snapshot.queryParams['storeId'],
      shippingId: this.shipment?.id,
      cartAmount: Math.round(this.itemsValue * 100),
      totalAmount: Math.round(this.totalValue * 100),
      totalTax: 0,
      shippingAmount: Math.round(this.shippingValue * 100),
      couponCode: this.coupon?.value,
    };
    if (this.payment?.paymentSelected === 'card') {
      otherValues = {
        ...otherValues,
        cardToken: this.payment.cardToken,
        installments: this.payment.installments,
      };
    }
    return { customer, products, customerAddress, updateItems, ...otherValues };
  }

  finishOrder() {
    if (this.isPreviewRoute) return;

    this.isLoading = true;
    const payload = this.constructFinalForm();
    this.mainDispatcher
      .finishOrder({
        payload,
        paymentType: this.payment?.paymentSelected,
      })
      .subscribe({
        next: this.treatFinishOrderSuccesfull,
        error: this.treatFinishOrderError,
      });
  }

  handleOpenCard() {
    this.active = !this.active;
  }

  private listenDelivery() {
    this.mainDispatcher.listenerDelivery().subscribe({
      next: this.treatLoadDeliverySuccesfull,
    });
  }

  private treatLoadDeliverySuccesfull = (res: any): void => {
    if (res?.zipCode) {
      this.deliveryData = res;
    }
  };

  get itemsValue(): number {
    return this.cartItems.reduce((total, item) => total + item.unitPrice * item.quantity, 0) / 100;
  }
  get discountValue(): number {
    return 0;
  }
  get discountPercentageLabel(): string {
    return this.percentageDiscount > 0
      ? `${this.percentageDiscount}%`
      : this.realDiscount > 0
      ? `${((this.realDiscount * 100) / this.itemsValue).toFixed(0)}%`
      : '';
  }
  get partialValue(): number {
    return this.itemsValue + this.discountValue;
  }
  get shippingValue(): number {
    return this.shipment?.price || 0;
  }
  get freeShipment(): boolean {
    return this.shipment?.price === 0;
  }
  get hasAddress(): boolean {
    return !!this.shipment;
  }
  get totalValue(): number {
    return this.partialValue + this.shippingValue;
  }
  get coupon(): AbstractControl | null {
    return this.couponForm.get('coupon');
  }
  get disableBuyButton(): boolean {
    if (this.isPreviewRoute) return false;
    return !this.hasAddress || this.isLoading || !this.payment || !this.personalInfo;
  }
  get isPreviewRoute(): boolean {
    return this.mainDispatcher.getIsPreviewRoute();
  }

  private startForm() {
    this.couponForm = this.fb.group({
      coupon: ['', Validators.required],
    });
  }

  private listenPersonalInfo() {
    this.mainDispatcher.listenerPersonalInfo().subscribe({
      next: this.treatLoadPersonalInfoSuccesfull,
    });
  }

  private treatLoadPersonalInfoSuccesfull = (res: any): void => {
    this.personalInfo = res;
  };

  private listenCart() {
    this.mainDispatcher.listenerCart().subscribe({
      next: this.treatLoadCartSuccesfull,
    });
  }

  private treatLoadCartSuccesfull = (res: any): void => {
    this.isLoading = false;
    this.originalCart = res.originalCart;
    this.cartItems = res?.cartItems || [];
  };

  private listenShipment() {
    this.mainDispatcher.listenerShipment().subscribe({
      next: this.treatLoadShipmentSuccesfull,
    });
  }

  private treatLoadShipmentSuccesfull = (res: any): void => {
    if (res?.id) {
      this.shipment = res;
    } else {
      this.shipment = null;
    }
  };

  private listenCoupon() {
    this.mainDispatcher.listenerCoupon().subscribe({
      next: this.treatCouponSuccesfull,
    });
  }

  private treatCouponSuccesfull = (res: any): void => {
    this.couponError = null;
    if (res?.temporaryCoupon) {
      this.coupon?.reset();
      this.coupon?.setValue(res?.temporaryCoupon);
    }
    if (res?.couponApplied) {
      this.couponApplied = res.couponApplied.data;
      this.coupon?.disable();
      this.percentageDiscount = this.couponApplied.valorDescontoPercentual;
      this.realDiscount = this.couponApplied.valorDescontoReal;
    }

    if (res.error) {
      this.couponError = this.parseError(res.error, 'Erro ao aplicar cupom');
    }
    this.isLoadingCoupon = false;
  };

  private listenPayment() {
    this.mainDispatcher.listenerPayment().subscribe({
      next: this.treatPaymentSuccesfull,
    });
  }

  private treatPaymentSuccesfull = (res: any): void => {
    this.payment = res;
  };

  private parseError = (err: any, defaultMessage: string): string => {
    return defaultMessage;
  };

  navigateToSuccess(res: any) {
    // this.mainDispatcher.resetAll();
    this.toastService.showToast('Compra finalizada com sucesso', 'success');
    this.router.navigate([`/pedido`, res.orderId, this.route.snapshot.queryParams['storeId']], {
      queryParams: { type: res.paymentType },
    });
  }

  private treatFinishOrderSuccesfull = (res: any): void => {
    this.isLoading = false;
    const allowedStatuses = ['pending', 'pre_authorized', 'authorized', 'captured', 'in_analysis', 'paid'];
    if (!allowedStatuses.includes(res?.status)) this.toastService.showToast('Erro ao finalizar compra', 'danger');
    else {
      const params = {
        ...res,
        paymentType: this.payment?.paymentSelected,
        totalValue: this.totalValue * 100,
        cardForm: this.payment?.cardOtherForm,
        shipping: this.shipment,
      };
      this.mainDispatcher.setPaymentReturn(params);
      this.navigateToSuccess(params);
    }
  };

  private treatFinishOrderError = (error: any): void => {
    this.isLoading = false;
    this.toastService.showToast(this.parseError(error, this.generateErrorMsg(error)), 'danger');
  };

  private generateErrorMsg = (err: any): string => {
    if (err?.status === 400) {
      return 'Erro no formulário de pagamento';
    } else if (err?.status === 401) {
      return 'Erro na autenticação';
    } else if (err?.status === 403) {
      return 'Erro na autorização';
    } else {
      return 'Erro ao finalizar compra';
    }
  };
}
