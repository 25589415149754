import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() title: string = '';
  @Input() subtitle?: string = '';
  @Input() disabled: boolean = false;
  @Input() hasHeader: boolean = true;
  @Input() limitHeight: boolean = true;
  @Input() stepCounter: number;
  @Input() iconTitle?: string;
  @Input() iconOpened?: string;
  @Input() iconClosed?: string;
  @Input() orientation:
    | 'VERTICAL'
    | 'HORIZONTAL'
    | 'FLEX'
    | 'COUPON'
    | 'FOOTER'
    | 'ORDER-RESUME' = 'VERTICAL';
  @Output() buttonClicked = new EventEmitter<any>();

  constructor() {}

  handleClick() {
    this.buttonClicked.emit();
  }
}
