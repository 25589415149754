import { Component, Input } from '@angular/core';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.scss'],
})
export class ShipmentComponent {
  @Input() active: boolean = false;

  labelPosition: 'before' | 'after' = 'after';
  isLoading: boolean = false;
  errorMessage: any;

  shipments: any = null;
  shipmentSelected: string;
  shipmentIntegrated: string;

  constructor(private mainDispatcher: MainDispatcher) {
    this.listenDelivery();
  }

  selectShipment() {
    this.isLoading = true;
    const shipment = this.shipments?.find(
      (shipment: any) => shipment.id === this.shipmentSelected
    );
    this.mainDispatcher.selectShipment(shipment);
  }

  private listenShipments() {
    this.mainDispatcher.listenerShipments().subscribe({
      next: this.treatLoadShipmentSuccesfull,
    });
  }

  private listenDelivery() {
    this.mainDispatcher.listenerDelivery().subscribe({
      next: this.treatLoadDeliverySuccesfull,
    });
  }

  private treatLoadDeliverySuccesfull = (res: any): void => {
    if (res?.zipCode) {
      this.listenShipments();
    } else {
      this.shipments = [];
      this.mainDispatcher.selectShipment({});
    }
  };

  private treatLoadShipmentSuccesfull = (res: any): void => {
    if (res?.shipments?.length > 0) {
      this.shipments = res.shipments;
      this.shipmentSelected = res.shipments[0].id;
      this.selectShipment();
    } else {
      this.shipments = null;
      this.shipmentSelected = '';
    }
    this.isLoading = false;
  };

  get variationText() {
    if (!this.shipments) {
      return 'Opções de entrega não disponíveis para o endereço informado.';
    } else {
      return 'Preencha o endereço de entrega para visualizar as opções.';
    }
  }
}
