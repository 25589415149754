<mat-card
  class="bee-card"
  [ngClass]="{
        'vertical-card': orientation === 'VERTICAL',
        'horizontal-card': orientation === 'HORIZONTAL',
        'horizontal-card-limited': limitHeight,
        'flex-card': orientation === 'FLEX',
        'coupon-card': orientation === 'COUPON',
        'footer-card': orientation === 'FOOTER',
        'order-card': orientation === 'ORDER-RESUME',
    }">
  <mat-card-header class="card-header-wp" *ngIf="title">
    <div class="card-header">
      <!-- <div *ngIf="stepCounter" class="badge">{{ stepCounter }}</div> -->
      <div *ngIf="iconTitle" class="card-icon" [innerHTML]="iconTitle"></div>

      <span>
        <small *ngIf="subtitle">{{ subtitle }}</small>
        <mat-card-title>{{ title }}</mat-card-title>
      </span>
    </div>
    <button *ngIf="iconOpened || iconClosed" mat-icon-button (click)="handleClick()">
      <mat-icon fontIcon="{{ disabled ? iconClosed : iconOpened }}" />
    </button>
  </mat-card-header>
  <mat-card-content *ngIf="!disabled">
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
