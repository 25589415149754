<app-full-page-loading [visible]="initialLoading"></app-full-page-loading>
<app-toolbar [isMobile]="isMobileView" [secondary]="showAdBar"></app-toolbar>

<div
  class="pages-container"
  [class]="{ 'has-adbar': showAdBar, 'has-header': !isMobileView }"
  *ngIf="!initialLoading"
>
  <div class="container-content">
    <div class="first-column">
      <app-order-detail *ngIf="isMobileView"></app-order-detail>

      <!-- <app-upsell></app-upsell> -->
      <app-personal-info
        [active]="['ACTIVE', 'COMPLETED'].includes(personalInfoStatus)"
      ></app-personal-info>
      <app-delivery
        [active]="['ACTIVE', 'COMPLETED'].includes(deliveryStatus)"
      ></app-delivery>

      <app-shipment
        [active]="['ACTIVE', 'COMPLETED'].includes(shipmentStatus)"
      ></app-shipment>

      <app-payment
        [active]="['ACTIVE', 'COMPLETED'].includes(paymentStatus)"
      ></app-payment>
    </div>

    <div class="second-column">
      <app-order-detail *ngIf="!isMobileView"></app-order-detail>
      <!-- <app-order-bump></app-order-bump> -->
      <app-finish-order />
    </div>
  </div>

  <app-footer />
</div>
