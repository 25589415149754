import { environment } from 'src/environments/environment';

export class CartEndpointMapper {
  // Checkout Carrinho
  // GET - ObterCarrinho
  static getCart: string = `${environment.APIS.FAVOSS}/checkout/cart`;

  // Checkout Configuracao
  // GET - Buscar personalizações de checkout
  static getCheckoutConfig: string = `${environment.APIS.FAVOSS}/seller`;

  // Checkout Pagamento
  // GET - Buscar Pedido
  static getOrder: string = `${environment.APIS.FAVOSS}/seller`;
  // POST - Pagamento Boleto
  static postBankSlipPayment: string = `${environment.APIS.FAVOSS}/checkout/bankslip`;
  // POST - Pagamento Cartao
  static postCardPayment: string = `${environment.APIS.FAVOSS}/checkout/card`;
  // POST - Pagamento Pix
  static postPixPayment: string = `${environment.APIS.FAVOSS}/checkout/pix`;
  // GET - Credenciais do gateway de pagamento
  static getGatewayCredentials: string = `${environment.APIS.FAVOSS}/checkout`;
}
