export class ThemeVariables {
  [x: string]: any;

  constructor(data: any) {
    // Logotipo
    this['logo'] = [
      {
        variable: '--app-logo',
        value: data['imageUrl'] ? `url(${data['imageUrl']}) no-repeat` : `url('/assets/images/logo.svg') no-repeat`,
      },
    ];

    // Cabeçalho
    this['headerBackgroundColor'] = [
      {
        variable: '--header-bg-color',
        value: data.header?.barColor || 'linear-gradient(90deg, #ff7955 40%, #fd267d 100%)',
      },
    ];
    this['headerTextColor'] = [{ variable: '--header-text-color', value: data.header?.elementsColor || '#ffffff' }];

    // Ícones
    this['iconColor'] = [{ variable: '--icon-color', value: data.appearance?.iconsColor || '#ff7955' }];

    // Botões
    this['buttonBackgroundColor'] = [
      { variable: '--button-bg-color', value: data.button?.backgroundColor || '#1b9b5e' },
    ];
    this['buttonTextColor'] = [{ variable: '--button-text-color', value: data.button?.textColor || '#ffffff' }];

    // Rodapé
    this['footerBackgroundColor'] = [
      { variable: '--footer-bg-color', value: data.footer?.backgroundColor || '#080b12' },
    ];
    this['footerTextColor'] = [{ variable: '--footer-text-color', value: data.footer?.textColor || '#ffffff' }];

    this['footerText'] = {
      value:
        data.footer?.text ||
        'Favoss é uma fintech que processa pagamento, ao clicar em finalizar a compra você concorda com nossos termos de uso e a nossa política de privacidade. Contate a loja em casos relacionados a garantia do produtos, duvidas ou reclamações.',
    };

    // Links no rodapé (opcionais)
    this['footerFirstLink'] = {
      description: data.footer?.firstLink?.description || 'Termos de uso',
      url: data.footer?.firstLink?.url || 'https://favoss-management.s3.amazonaws.com/termos_de_uso_Favoss_v2.pdf',
    };

    this['footerSecondLink'] = {
      description: data.footer?.secondLink?.description || 'Política de privacidade',
      url:
        data.footer?.secondLink?.url ||
        'https://favoss-management.s3.amazonaws.com/politica_de_privacidade_Favoss_v2.pdf',
    };
  }
}
