import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ThemeVariables } from '../../models/theme-variables';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CartEndpointMapper } from '../../mappers/cart-endpoint.mapper';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeData: any = null;
  private pageBody: any = document.querySelector('html');

  constructor(@Inject(DOCUMENT) private _document: any, private title: Title, private http: HttpClient) {}

  getStoreSettings(storeId: string): Observable<any> {
    return this.http.get(`${CartEndpointMapper.getCheckoutConfig}/${storeId}/checkout/theme`);
  }

  start(payload: any): any {
    this.themeData = new ThemeVariables(payload);

    if (payload?.dadosLoja?.nome) this.setPageTitle(payload.dadosLoja.nome);
    if (payload?.dadosLoja?.urlFavIcon) this.setFavicon(payload.dadosLoja.urlFavIcon);

    this.changeTheme();
  }

  private changeTheme() {
    Object.entries(this.themeData).map((entry: [string, any]) => {
      if (entry[1] && entry[1].length > 0) {
        entry[1].map((item: any) => {
          if (item.variable) {
            // Aplica a variável CSS no root do documento
            this.pageBody.style.setProperty(item.variable, item.value);
          }
        });
      }
    });
  }

  private setPageTitle(newTitle: string): void {
    this.title.setTitle(newTitle);
  }

  private setFavicon(uri: any): void {
    this._document.getElementById('favicon').setAttribute('href', uri);
  }

  getVariables(): any {
    return this.themeData || new ThemeVariables({});
  }
}
