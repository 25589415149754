import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Observable, from, map, of, switchMap } from 'rxjs';
import { BaseService } from '../base.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private USER_DATA: any;

  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  userId(): string {
    return this.USER_DATA?.token?.tokenUsuario?.id;
  }

  userEmail(): string {
    return this.USER_DATA?.token?.tokenUsuario?.email;
  }

  isAuthenticated(): boolean {
    return this.USER_DATA?.token?.accessToken ? true : false;
  }

  getAuthHeader = (): { headers: HttpHeaders } => {
    return {
      headers: new HttpHeaders({
        Authorization: window['env'].BACKEND_AUTH_TOKEN,
      }),
    };
  };
}
