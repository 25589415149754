<app-card [title]="'Escolha a transportadora'" [stepCounter]="3" [disabled]="!active">
  <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
    <span>
      {{ errorMessage }}
    </span>
  </app-in-page-toast>

  <div fxLayout="column" fxLayoutGap="10px" *ngIf="shipments?.length">
    <div *ngFor="let item of shipments" class="checkbox-area" [class]="shipmentSelected === item.id ? 'selected' : ''">
      <div class="orientation">
        <mat-radio-group [(ngModel)]="shipmentSelected" (change)="selectShipment()" data-test-id="shipment-radio-group">
          <mat-radio-button [value]="item.id" [attr.data-test-id]="'shipment-option-' + item.id">
            <span class="name">
              {{ item.name }}
            </span>
            <p class="time" *ngIf="item.deliveryTime > 0">
              {{ item.deliveryTime }} Dia{{ item.deliveryTime > 1 ? 's' : '' }}
            </p>
            <p class="time" *ngIf="item.deliveryTime === 0">Entrega imediata</p>
          </mat-radio-button>
        </mat-radio-group>
        <span class="value" *ngIf="item.price > 0">
          <span class="original-value" *ngIf="item.originalPrice">
            {{ item.originalPrice | currency : 'BRL' }}
          </span>
          {{ item.price | currency : 'BRL' }}
        </span>
        <span class="value" *ngIf="item.price === 0">Grátis</span>
      </div>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="10px" *ngIf="!shipments?.length">
    {{ variationText }}
  </div>
</app-card>
