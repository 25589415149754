import { ResponsiveService } from '../../core/services/responsive/responsive.service';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-home-preview',
  templateUrl: './home-preview.component.html',
  styleUrls: ['./home-preview.component.scss'],
})
export class HomePreviewComponent {
  initialLoading: boolean = true;
  isMobileView: boolean = false;
  showAdBar: boolean = false;

  personalInfoStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';
  deliveryStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';
  shipmentStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';
  paymentStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';
  finishOrderStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';

  constructor(private mainDispatcher: MainDispatcher, private route: ActivatedRoute) {
    this.listenSettings();
  }

  private listenSettings() {
    this.initialLoading = true;

    const { storeId } = this.route.snapshot.params;
    this.mainDispatcher.requestGetStoreSettings(storeId).subscribe({
      next: (res) => {
        this.mainDispatcher.treatGetStoreSettingsSuccesfull(res);
        this.initialLoading = false;
      },
      error: () => {
        this.initialLoading = false;
      },
    });
  }

  treatLoadSettingsSuccesfull = (): void => {
    this.initialLoading = false;
  };
}
