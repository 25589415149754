<app-card
  *ngIf="orderBumps && orderBumps.length > 0"
  [title]="'Recomendados para você!'"
  [subtitle]="'Aproveite a oferta!'"
  [iconTitle]="'assets/images/ofert.svg'"
  [orientation]="'COUPON'"
>
  <app-in-page-toast
    *ngIf="errorMessage"
    type="ERROR"
    [closable]="true"
    (closed)="errorMessage = null"
  >
    <span>
      {{ errorMessage }}
    </span>
  </app-in-page-toast>
  <div class="items-container multiple-items-container">
    <div class="product-wrapper" *ngFor="let item of orderBumps; let i = index">
      <div class="title-area">
        <div
          class="img img-small"
          [ngStyle]="{ background: 'url(' + item?.caminhoImagem + ')' }"
        ></div>
        <div class="info-wrapper">
          <div fxLayout="column wrap">
            <span class="item-name">
              {{ item?.nomeProduto }}
            </span>
            <span class="variations">
              {{ item?.mensagemOrderBump }}
            </span>
          </div>

          <div class="price-area">
            <div>
              <span class="price">
                {{ item?.precoPromocional | currency : "BRL" }}
              </span>
              <span class="discount">
                {{ item?.precoVenda | currency : "BRL" }}
              </span>
            </div>
          </div>

          <button
            mat-button
            class="add-to-cart"
            [disabled]="
              isLoadingItem === item.idOrderBump ||
              orderBumpsAddedToCart.includes(item.idOrderBump)
            "
            (click)="addToCart(item)"
          >
            <span *ngIf="!(isLoadingItem === item.idOrderBump)">
              <img src="assets/images/cart.svg" alt="cart icon" />
              {{ item?.textoBotao || "Adicionar ao carrinho!" }}
            </span>
            <mat-progress-spinner
              *ngIf="isLoadingItem === item.idOrderBump"
              diameter="25"
              strokeWidt="2"
              mode="indeterminate"
            >
            </mat-progress-spinner>
          </button>
        </div>
      </div>

      <div
        class="quantity"
        *ngIf="settings?.resumo?.permitirMudancaQuantidadeItem || true"
      >
        <button
          mat-mini-fab
          class="mini-fab-button"
          [disabled]="isLoadingQuantity"
          (click)="changeItemQuantity(item, item.quantidade + 1, i)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <span *ngIf="!isLoadingQuantity">
          {{ item?.quantidade }}
        </span>
        <span *ngIf="isLoadingQuantity">
          <mat-progress-spinner
            matSuffix
            diameter="22"
            strokeWidt="1"
            mode="indeterminate"
          >
          </mat-progress-spinner>
        </span>
        <button
          mat-mini-fab
          class="mini-fab-button"
          [disabled]="isLoadingQuantity"
          (click)="changeItemQuantity(item, item.quantidade - 1, i)"
        >
          <mat-icon> remove </mat-icon>
        </button>
      </div>
    </div>
  </div>
</app-card>
