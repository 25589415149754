<app-card
  [title]="'Resumo do pedido'"
  [disabled]="!active"
  (buttonClicked)="handleOpenCard()"
  [orientation]="'ORDER-RESUME'"
  iconClosed="keyboard_arrow_down"
  iconOpened="keyboard_arrow_up"
  [iconTitle]="cartIcon">
  <div class="items-container multiple-items-container">
    <span class="product-wrapper" *ngFor="let item of cartItems; let i = index">
      <div *ngIf="item?.quantity">
        <div class="title-area">
          <div class="img img-small" [ngStyle]="{ background: 'url(' + item?.imageUrl + ')' }"></div>
          <div class="info-wrapper">
            <div fxLayout="column wrap">
              <div class="item-name">
                {{ item?.title || item?.variantTitle }}
              </div>
              <div class="variations" *ngIf="item?.title">
                {{ item?.variantTitle }}
              </div>
            </div>
            <div class="price-area">
              <div>
                <span class="price" *ngIf="item?.unitPrice">
                  {{ item?.unitPrice / 100 | currency : 'BRL' }}
                </span>
                <span class="discount" *ngIf="item?.initialPrice">
                  {{ item?.initialPrice / 100 | currency : 'BRL' }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="quantity" *ngIf="settings?.resumo?.permitirMudancaQuantidadeItem || true">
          <button
            mat-mini-fab
            data-test-id="add-cart-item-btn"
            class="mini-fab-button"
            [disabled]="isLoading"
            (click)="changeItemQuantity(item, item.quantity + 1, i)">
            <mat-icon>add</mat-icon>
          </button>
          <span *ngIf="!isLoading">
            {{ item?.quantity }}
          </span>
          <span *ngIf="isLoading">
            <mat-progress-spinner matSuffix diameter="22" strokeWidt="1" mode="indeterminate"></mat-progress-spinner>
          </span>
          <button
            mat-mini-fab
            data-test-id="remove-cart-item-btn"
            class="mini-fab-button"
            [class]="{ 'trash-icon': item?.quantity === 1 }"
            [disabled]="isLoading || totalItems === 1"
            (click)="changeItemQuantity(item, item.quantity - 1, i)">
            <mat-icon>{{ item?.quantity === 1 ? 'delete' : 'remove' }}</mat-icon>
          </button>
        </div>
      </div>
      <span class="product-wrapper" *ngIf="hasNoItems">
        <p>Carrinho vazio</p>
      </span>
      <hr *ngIf="cartItems.length - 1 !== i" />
    </span>
  </div>
</app-card>

<app-testimonials></app-testimonials>
