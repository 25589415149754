<app-card [title]="'Dados pessoais'" [stepCounter]="1" [disabled]="!active">
  <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
    <span>
      {{ errorMessage }}
    </span>
  </app-in-page-toast>

  <form [formGroup]="personalInfoForm" data-test-id="personal-info-form" *ngIf="isFormOpen">
    <div fxLayout="column" fxLayoutGap="14px">
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Nome</mat-label>
        <input matInput data-test-id="full-name-input" required formControlName="fullName" />
        <mat-icon *ngIf="personalInfoForm?.touched && !fullName?.invalid" matSuffix fontIcon="check_circle" />

        <mat-error *ngIf="personalInfoForm?.touched && fullName?.invalid">O valor é inválido</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Contato</mat-label>
        <input
          matInput
          data-test-id="phone-number-input"
          required
          formControlName="phone"
          mask="(00) 0 0000-0000"
          placeholder="ex 11 9 0000-0000" />
        <mat-icon matSuffix *ngIf="personalInfoForm?.touched && !phone?.invalid" fontIcon="check_circle" />

        <mat-error *ngIf="personalInfoForm?.touched && phone?.invalid">O valor é inválido</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>E-mail</mat-label>
        <input matInput data-test-id="email-input" required formControlName="email" placeholder="exemplo@exemplo.com" />
        <mat-icon matSuffix *ngIf="personalInfoForm?.touched && !email?.invalid" fontIcon="check_circle" />

        <mat-error *ngIf="personalInfoForm?.touched && email?.invalid">O valor é inválido</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>CPF</mat-label>
        <input
          matInput
          data-test-id="cpf-input"
          required="false"
          formControlName="cpf"
          mask="000.000.000-00"
          required />
        <mat-icon matSuffix *ngIf="personalInfoForm?.touched && !cpf?.invalid" fontIcon="check_circle" />

        <mat-error *ngIf="personalInfoForm?.touched && cpf?.invalid">O valor é inválido</mat-error>
      </mat-form-field>
    </div>
  </form>
</app-card>
