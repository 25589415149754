import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private snackbar: MatSnackBar) {}

  showToast(
    message: string,
    type: 'danger' | 'success' | 'warning' = 'success',
    duration: number = 2000
  ) {
    const toastOptions: MatSnackBarConfig = {
      duration,
      politeness: 'polite',
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: {
        danger: 'toast-danger',
        success: 'toast-success',
        warning: 'toast-warning',
      }[type],
    };
    this.snackbar.open(message, undefined, toastOptions);
  }
}
