import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.scss'],
})
export class UpsellComponent {
  @Input() validAt: 'No Checkout' | 'Na Finalização' = 'No Checkout';

  cart: any;
  settings: any;
  errorMessage: any;
  prePaymentSelected: any;
  upsells: any[] = [];
  upsellsAddedToCart: any[] = [];

  isLoadingItem: string;

  constructor(private fb: FormBuilder, private mainDispatcher: MainDispatcher) {
    this.listenSettings();
    this.listenPayment();
    this.listenUpsell();
  }

  addToCart(item: any) {
    this.isLoadingItem = item.id;
    // this.mainDispatcher.addUpsellToCart(item);
  }

  redirectToNewCheckout(item: any) {
    this.mainDispatcher.startNewBuy(item.skuSelected);
  }

  changeVariation(event: any, variationTitle: string, upsellId: string) {
    this.upsells.map((upsell: any, upsellIndex: number) => {
      if (upsell.id === upsellId) {
        upsell.produto.skus.map((sku: any) => {
          let sameVariation = 0;
          sku.variacoes.map((variation: any) => {
            if (
              this.upsells[upsellIndex]['selected' + variation.nomeVariacao] &&
              variation.valorVariacao === this.upsells[upsellIndex]['selected' + variation.nomeVariacao]
            ) {
              sameVariation++;
            }
            if (sameVariation === sku.variacoes.length) this.upsells[upsellIndex].skuSelected = sku;
          });
        });
      }
    });
  }

  private listenSettings() {
    this.mainDispatcher.listenerSettings().subscribe({
      next: this.treatLoadSettingsSuccesfull,
    });
  }

  private treatLoadSettingsSuccesfull = (res: any): void => {
    this.settings = res;
  };

  private listenPayment() {
    this.mainDispatcher.listenerPayment().subscribe({
      next: this.treatPaymentSuccesfull,
    });
  }

  private treatPaymentSuccesfull = (res: any): void => {
    if (res?.prePaymentSelected) {
      this.prePaymentSelected = res.prePaymentSelected;
    }
  };

  private listenUpsell() {
    this.mainDispatcher.listenerUpsell().subscribe({
      next: this.treatUpsellSuccesfull,
    });
  }

  private treatUpsellSuccesfull = (res: any): void => {
    if (res?.upsells) {
      let upsells = res.upsells
        .filter((upsell: any) => upsell.quandoOferecerDescricao === this.validAt)
        .map((upsell: any) => {
          let availableVariations: any = {};
          upsell?.produto?.skus.map((sku: any) => {
            sku?.variacoes?.map((variacao: any) => {
              availableVariations[variacao.nomeVariacao]
                ? availableVariations[variacao.nomeVariacao].push(variacao.valorVariacao)
                : (availableVariations[variacao.nomeVariacao] = [variacao.valorVariacao]);
            });
          });
          upsell.availableVariations = Object.entries(availableVariations).map((pair) => {
            return { title: pair[0], options: [...new Set(<Array<string>>pair[1])] };
          });
          upsell.caminhoImagem = upsell.produto.skus[0].skuImagem[0].url;

          if (upsell.produto.skus.length === 1) {
            upsell.skuSelected = upsell.produto.skus[0];
          }
          return upsell;
        });
      this.upsells = upsells;
    }
    if (res?.addedToCart) {
      this.isLoadingItem = '';
      this.upsellsAddedToCart = [...this.upsellsAddedToCart, res.addedToCart];
    }
    if (res?.errorMessage) {
      this.errorMessage = res?.errorMessage;
      this.isLoadingItem = '';
    }
  };
}
