import { Component, Input, OnInit } from '@angular/core';
import { ResponsiveService } from './../../core/services/responsive/responsive.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { cpfValidator } from 'src/helpers/cpf-validator';

import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent implements OnInit {
  @Input() active: boolean = false;

  cpfMask: any = '000.000.000-00';
  phoneMask: any = '(00) 00000-0000';
  isMobileView: boolean = false;

  isFormOpen: boolean = true;
  isEdit: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;

  personalInfoForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private mainDispatcher: MainDispatcher,
    private responsiveService: ResponsiveService
  ) {}

  ngOnInit() {
    this.startForm();
    this.isMobileView = this.responsiveService.isSmallScreen;
  }

  handleOpenCard() {
    this.isFormOpen = !this.isFormOpen;
    this.isEdit = false;
  }

  startForm() {
    this.personalInfoForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ],
      fullName: [
        '',
        [Validators.required, Validators.pattern(/^(?=\S*\s\S+).*$/)],
      ],
      cpf: ['', [cpfValidator, Validators.required]],
      phone: ['', [Validators.required]],
    });
    this.listenFormChanges();
  }

  listenFormChanges() {
    this.personalInfoForm.valueChanges.subscribe((value) => {
      if (!this.personalInfoForm.invalid)
        this.mainDispatcher.savePersonalInfo(value);
      else this.mainDispatcher.savePersonalInfo(null);
    });
  }

  get email(): AbstractControl | null {
    return this.personalInfoForm.get('email');
  }
  get fullName(): AbstractControl | null {
    return this.personalInfoForm.get('fullName');
  }
  get cpf(): AbstractControl | null {
    return this.personalInfoForm.get('cpf');
  }
  get phone(): AbstractControl | null {
    return this.personalInfoForm.get('phone');
  }
}
