import { AbstractControl, ValidationErrors } from '@angular/forms';

export function cpfValidator(
  control: AbstractControl
): ValidationErrors | null {
  const cpf = control.value;

  if (!cpf) {
    return null; // CPF não é obrigatório
  }

  // Remove caracteres especiais
  const cleanedCpf = cpf.replace(/[^\d]+/g, '');

  // Verifica se o CPF tem 11 dígitos
  if (cleanedCpf.length !== 11) {
    return { cpfInvalid: true };
  }

  // Verifica se todos os dígitos são iguais (ex: 111.111.111-11)
  if (/^(\d)\1+$/.test(cleanedCpf)) {
    return { cpfInvalid: true };
  }

  // Validação do dígito verificador
  let sum;
  let remainder;
  sum = 0;
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cleanedCpf.substring(i - 1, i)) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cleanedCpf.substring(9, 10))) {
    return { cpfInvalid: true };
  }
  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cleanedCpf.substring(i - 1, i)) * (12 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cleanedCpf.substring(10, 11))) {
    return { cpfInvalid: true };
  }

  return null; // CPF válido
}
