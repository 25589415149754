<app-card [title]="'Endereço de entrega'" [stepCounter]="2" [disabled]="!active">
  <div fxLayout="column">
    <app-in-page-toast *ngIf="errorMessage" type="ERROR" [closable]="true" (closed)="errorMessage = null">
      <span>
        {{ errorMessage }}
      </span>
    </app-in-page-toast>

    <form [formGroup]="deliveryForm" data-test-id="delivery-address-form" *ngIf="isFormOpen">
      <div fxLayout="column" fxLayoutGap="14px">
        <div fxFlex>
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>CEP</mat-label>
            <input
              matInput
              required
              data-test-id="zip-code-input"
              formControlName="zipCode"
              mask="00000-000"
              placeholder="ex00000-000" />
            <mat-icon
              matSuffix
              *ngIf="deliveryForm?.touched && !cep?.invalid && !isLoadingCep"
              fontIcon="check_circle" />
            <mat-progress-spinner
              *ngIf="isLoadingCep"
              matSuffix
              diameter="22"
              strokeWidt="1"
              mode="indeterminate"></mat-progress-spinner>
            <mat-error *ngIf="deliveryForm?.touched && cep?.invalid">O campo é inválido</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="cep?.valid && !isLoadingCep" fxFlex="row wrap" fxLayoutGap="12px">
          <mat-form-field appearance="outline" fxFlex="30">
            <mat-label>Estado</mat-label>
            <input
              matInput
              required
              data-test-id="state-input"
              autocomplete="false"
              mask="SS"
              role="presentation"
              formControlName="state" />
            <mat-icon matSuffix *ngIf="deliveryForm?.touched && !state?.invalid" fontIcon="check_circle" />
            <mat-error *ngIf="deliveryForm?.touched && state?.invalid">O campo é inválido</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="70">
            <mat-label>Cidade</mat-label>
            <input matInput data-test-id="city-input" required formControlName="city" />
            <mat-icon matSuffix *ngIf="deliveryForm?.touched && !city?.invalid" fontIcon="check_circle" />
            <mat-error *ngIf="deliveryForm?.touched && city?.invalid">O campo é inválido</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="cep?.valid && !isLoadingCep" fxFlex>
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Endereço</mat-label>
            <input matInput data-test-id="address-input" required formControlName="address" />
            <mat-icon matSuffix *ngIf="deliveryForm?.touched && !address?.invalid" fontIcon="check_circle" />
            <mat-error *ngIf="deliveryForm?.touched && address?.invalid">O campo é inválido</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="cep?.valid && !isLoadingCep" fxFlex="row wrap" fxLayoutGap="12px">
          <mat-form-field appearance="outline" fxFlex="30">
            <mat-label>Número</mat-label>
            <input matInput data-test-id="number-input" required formControlName="number" />
            <mat-icon matSuffix *ngIf="deliveryForm?.touched && !number?.invalid" fontIcon="check_circle" />
            <mat-error *ngIf="deliveryForm?.touched && number?.invalid">Obrigatório</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="70">
            <mat-label>Complemento (opcional)</mat-label>
            <input
              matInput
              data-test-id="complement-input"
              required="false"
              formControlName="complement"
              placeholder="Ex: Rua, Av..." />
          </mat-form-field>
        </div>
        <div *ngIf="cep?.valid && !isLoadingCep" fxFlex>
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Bairro</mat-label>
            <input data-test-id="district-input" matInput required formControlName="district" />
            <mat-icon matSuffix *ngIf="deliveryForm?.touched && !district?.invalid" fontIcon="check_circle" />
            <mat-error *ngIf="deliveryForm?.touched && district?.invalid">O campo é inválido</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</app-card>
