import { Component } from '@angular/core';
import { ThemeService } from 'src/app/core/services/theme/theme.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  footerText: string = '';
  firstLink: any = null;
  secondLink: any = null;

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    const variables = this.themeService.getVariables();

    this.footerText = variables.footerText.value;
    this.firstLink = variables.footerFirstLink;
    this.secondLink = variables.footerSecondLink;
  }
}
