import { ResponsiveService } from './../../core/services/responsive/responsive.service';
import { Component } from '@angular/core';
import { MainDispatcher } from 'src/app/core/dispatchers/main.dispatcher';

@Component({
  selector: 'app-home-container',
  templateUrl: './home-container.component.html',
  styleUrls: ['./home-container.component.scss'],
})
export class HomeContainerComponent {
  initialLoading: boolean = true;
  isMobileView: boolean = false;
  showAdBar: boolean = false;

  personalInfoStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';
  deliveryStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';
  shipmentStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';
  paymentStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';
  finishOrderStatus: 'ACTIVE' | 'INACTIVE' | 'COMPLETED' = 'ACTIVE';

  constructor(
    private mainDispatcher: MainDispatcher,
    private responsiveService: ResponsiveService
  ) {
    this.listenSettings();
    this.listenCart();
    this.listenPersonalInfo();
  }

  private listenSettings() {
    this.mainDispatcher.listenerSettings().subscribe({
      next: this.treatLoadSettingsSuccesfull,
    });
  }

  private treatLoadSettingsSuccesfull = (res: any): void => {};

  private listenCart() {
    this.mainDispatcher.listenerCart().subscribe({
      next: this.treatLoadCartSuccesfull,
    });
  }

  private treatLoadCartSuccesfull = (res: any): void => {
    if (res?.originalCart?.id) {
      this.initialLoading = false;
      // this.isMobileView = this.responsiveService.isSmallScreen;
    }
  };

  private listenPersonalInfo() {
    this.mainDispatcher.listenerPersonalInfo().subscribe({
      next: this.treatLoadPersonalInfoSuccesfull,
    });
  }

  private treatLoadPersonalInfoSuccesfull = (res: any): void => {
    if (res?.idCliente) {
      this.personalInfoStatus =
        this.personalInfoStatus === 'ACTIVE'
          ? 'COMPLETED'
          : this.personalInfoStatus;
      this.deliveryStatus =
        this.deliveryStatus === 'INACTIVE' ? 'ACTIVE' : this.deliveryStatus;
    }
  };
}
