<mat-toolbar>
  <mat-toolbar-row class="primary-toolbar" *ngIf="!isMobile">
    <div class="toolbar-container">
      <div class="logo-wrapper">
        <div class="logo"></div>
        <img class="beta-img" src="assets/images/beta.svg" alt="beta logo" />
      </div>
      <div class="secure">
        <mat-icon>verified_user</mat-icon>
        <p>
          Pagamento
          <br />
          100% Seguro!
        </p>
      </div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row *ngIf="secondary && (adBarText || hasChronometer)" class="secondary-toolbar">
    <div class="content">
      <div class="title" *ngIf="adBarText">
        <img *ngIf="adBarIcon" [src]="adBarIcon" alt="icon" />
        <span [innerHTML]="adBarText"></span>
      </div>
      <span class="featured" *ngIf="hasChronometer">
        <mat-icon>schedule</mat-icon>
        {{ hours }}:{{ minutes }}:{{ seconds }}
      </span>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
