<div>
  <app-card
    [title]="'Selecione a forma de pagamento'"
    [stepCounter]="4"
    [orientation]="'VERTICAL'"
    [disabled]="!active">
    <div class="container" fxFlex="row wrap">
      <div fxLayout="column" fxLayoutGap="10px" fxFlex="100">
        <div
          class="payment-data"
          *ngFor="let item of availablePaymentTypes"
          [class]="paymentTypeSelected === item.id ? 'selected' : ''">
          <div class="orientation">
            <div class="payment-name" fxLayout="row">
              <div class="card-icon" [innerHTML]="item.icon"></div>

              <h5>{{ item.name }}</h5>
            </div>
            <mat-radio-group
              data-test-id="payment-radio-group"
              aria-labelledby="selected-payment"
              [(ngModel)]="paymentTypeSelected"
              (change)="selectPayment()">
              <mat-radio-button
                [attr.data-test-id]="'payment-option-' + item.id"
                class="payment"
                [value]="item.id"></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
  </app-card>

  <app-card [title]="''" [stepCounter]="5" [orientation]="'VERTICAL'" [disabled]="!active" *ngIf="isCreditCard">
    <div fxLayout="column">
      <form [formGroup]="paymentForm" data-malga-tokenization-form>
        <div class="payment-creditcard-container">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Número do Cartão</mat-label>
            <input
              matInput
              data-test-id="card-number-input"
              required
              formControlName="number"
              mask="0000 0000 0000 0000" />
            <mat-error *ngIf="paymentForm?.touched && cardNumber?.invalid">O Número do Cartão é inválido</mat-error>
          </mat-form-field>

          <div class="row-div">
            <mat-form-field appearance="outline" fxFlex="50">
              <mat-label>Validade</mat-label>
              <input
                matInput
                required
                data-test-id="expiration-date-input"
                formControlName="expirationDate"
                mask="00/0000"
                data-malga-tokenization-expiration-date />
              <mat-error *ngIf="paymentForm?.touched && validDate?.invalid">A Validade é inválida</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="50">
              <mat-label>CVV</mat-label>
              <input
                matInput
                required
                data-test-id="cvv-input"
                formControlName="cvv"
                mask="000"
                data-malga-tokenization-cvv />
              <mat-error *ngIf="paymentForm?.touched && cvv?.invalid">O CVV é inválido</mat-error>
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Nome do Titular</mat-label>
            <input
              matInput
              required
              data-test-id="holder-name-input"
              formControlName="holderName"
              data-malga-tokenization-holder-name />
            <mat-error *ngIf="paymentForm?.touched && holderName?.invalid">O Nome é inválido</mat-error>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            fxFlex="30"
            fxFlex.lt-lg="100"
            *ngIf="cardNumber?.value?.length > 4 && cardNumber?.valid">
            <mat-label>Número de Parcelas</mat-label>
            <mat-select data-test-id="installments-select" formControlName="installments">
              <mat-option
                *ngFor="let item of availableInstallments"
                [attr.data-test-id]="'installment-option-' + item"
                [value]="item">
                {{ item }}x Sem juros
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <input
          style="visibility: hidden; height: 0; width: 0; border: none; position: absolute; pointer-events: none"
          [value]="trimedCardNumber"
          mask="0000000000000000"
          data-malga-tokenization-number />
      </form>

      <div *ngIf="errorMessage" class="actions-container" fxLayout="row wrap">
        <app-in-page-toast type="ERROR" [closable]="true" (closed)="errorMessage = null">
          <span>
            {{ errorMessage }}
          </span>
        </app-in-page-toast>
      </div>
    </div>
  </app-card>
</div>
